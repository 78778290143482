@use '../../Assets/css/var';

footer {
    position: sticky;
    top: 100%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0);
    border-top: 1px solid #392739;
    box-shadow: 0px 0px 8px -2px #000000;
    margin-top: 70px;

    p {
        margin: 0px;
        text-align: left;
    }

    .socialIcons {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            list-style: none;
            padding: 0px;
            margin: 0px;

            li {
                margin: 0px 8px;

                a {
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid var.$colour-theme-dark;
                }

                svg {
                    fill: var.$colour-theme;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    padding: 10px;
                }
            }
        }
    }
}

// Footer Css End