@use '../../Assets/css/var';

.faqOuterSection {
    .accordion-item {
        background-color: transparent;
        border: none;
        margin-bottom: 15px;
        border: 1px solid #523064;
        border-radius: 5px;
    }

    .treebox {
        padding: 0px;
        border-radius: 5px;
        text-align: left;
        background-color: transparent;
        border: none;
        box-shadow: inset 0px 0px 35px 3px rgba(229, 149, 255, 0.36);
        color: #ffffff;
        height: 100%;
        cursor: pointer;

        .accordion-button {
            padding: 20px;
            background-color: transparent;
            color: #ffffff;
            outline: none;
            box-shadow: none;

            &::after {
                filter: grayscale(1) invert(1);
            }
        }
    }

    .accordion-body {
        padding: 15px;

        p {
            margin: 0px 0px 10px;
            color: #c9c9c9;

            a {
                color: #bf93ff;
            }
        }
    }
}