@media only screen and (max-width: 1149px) {
  .accountPreviewMain .tablemainnet .tableInr table tr td {
    font-size: 14px;
  }

  .accountPreviewMain .tablemainnet .tableInr table tr td a {
    font-size: 14px;
  }

  .accountPreviewMain .tablemainnet .mainnetInr .autoLogin .am_btn {
    min-width: auto;
  }

  .accountPreviewMain .tablemainnet .tableInr .dashboardPg table {
    min-width: auto;

    tr {
      flex-wrap: wrap;
      justify-content: space-between;

      td {

        &:nth-child(1) {
          flex: 0 0 50%;
          max-width: 50%;
          padding-bottom: 0px;
        }

        &:nth-child(2) {
          flex: 0 0 50%;
          max-width: 50%;
          text-align: right;
          padding-bottom: 0px;
        }

        &:nth-child(3) {
          flex: 0 0 50%;
          max-width: 50%;
          padding-bottom: 0px;

          &>span:nth-child(1) {
            margin-left: 0px;
          }
        }

        &:nth-child(4) {
          flex: 0 0 50%;
          max-width: 50%;
          text-align: right;
          padding-bottom: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .dashboardMian .inrBox h3 {
    font-size: 32px;
  }

  .loginMian .inrBox svg,
  .loginMian .inrBox img {
    width: 40px !important;
    height: 40px !important;
  }

  .tablemainnet .inrBox svg,
  .tablemainnet .inrBox img {
    width: 40px !important;
    height: 40px !important;
  }
}

@media only screen and (max-width: 992px) {
  .treeBoxSingleView .treebox .itemsmain span {
    width: 80px;
    height: 80px;
    font-size: 30px;
  }

  .treeBoxSingleView .treebox .itemsmain .col6 .col6 span {
    width: 70px;
    height: 70px;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .treeBoxSingleView .treebox .itemsmain .col6 .col6 .col6 span {
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .treeBoxSingleView .treebox .itemsmain .col6 .col6 .col6 .col6 span {
    width: 22px;
    height: 22px;
    font-size: 13px;
  }

  .treeBoxSingleView .treeboxDetail .itemsmain span {
    width: 80px;
    height: 80px;
    font-size: 30px;
  }

  .treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 span {
    width: 65px;
    height: 65px;
    font-size: 25px;
    margin-bottom: 10px;
  }

  .treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 .col6 span {
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 .col6 .col6 span {
    width: 22px;
    height: 22px;
    margin-bottom: 0px;

    img {
      padding: 3px;
    }
  }

  .treeBoxSingleView .treeboxDetail .itemsmain span img {
    padding: 10px;
  }

  .dashboardPreviewMain .tablemainnet .tableInr table tr th {
    width: auto;
  }

  .padingR-0-mob {
    padding-right: calc(var(--cui-gutter-x) * .5) !important;
  }

  .sidebarMenu {
    flex: 0 0 100%;
    max-width: 100% !important;
    margin-right: 0px;
    position: fixed;
    top: unset;
    align-self: flex-start;
    bottom: 0px;
    background-color: #000000;
    padding-top: 0px;
    z-index: 3;
    left: 0px;
    border-top: 1px solid #392739;
    box-shadow: 0px 0px 8px -2px #000000;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        margin: 0px 10px 0px;

        a {
          display: block;
          text-align: center;
          padding: 15px 5px;

          svg {
            display: block;
            margin: 0px auto 5px;
          }

          span {
            display: block;
            text-align: center;
          }
        }
      }
    }
  }

  .mainBoxWrap .rightBoxMain {
    flex-basis: 100%;
    max-width: 100%;
    padding-bottom: 120px;
  }

  footer {
    display: block;
    margin-top: 30px;

    p {
      text-align: center;
    }
  }

  footer .socialIcons ul li {
    margin: 0px 3px 6px;
  }

  .royaltyTableMain .tableInr table tr td,
  .royaltyTableMain .tableInr table tr th {
    font-size: 14px;
  }

  #root {
    padding-bottom: 80px;
  }

  section.faqOuterSection+footer {
    margin-bottom: -65px;
  }

  .accountPreviewMain .tablemainnet.rightBox .mainnetInr.reglogBox {
    min-height: auto;
  }

  .accountPreviewMain .tablemainnet .mainnetInr.setminheight {
    min-height: auto;
  }
}

@media only screen and (max-width: 767px) {

  .container,
  .slider-container {
    max-width: calc(100% - 15px);
  }

  .am_btn {
    font-size: 16px;
  }

  .tblMainnetMain .tablemainnet .tableInr .table-responsive {
    max-height: unset;
  }

  .treeBoxSingleView .treebox .itemsmain span {
    width: 70px;
    height: 70px;
  }

  .treeBoxSingleView .treebox .itemsmain .col6 .col6 span {
    width: 55px;
    height: 55px;
    font-size: 20px;
  }

  .treeBoxSingleView .treebox .itemsmain .col6 .col6 .col6 span {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }

  .treeBoxSingleView .treebox .itemsmain .col6 .col6 .col6 .col6 span {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  .dateFormSection .dateFormInr {
    padding: 10px 10px 15px;

    .col-sm-3 {
      padding: 0 5px;
    }

    .css-1jqq78o-placeholder {
      font-size: 16px;
    }
  }

  .dateFormSection .inrBoxSubmitBtn {
    position: relative;
    right: 0px;
    bottom: 0px;
    width: 100px;
    text-align: center;
    margin: 20px auto 5px;
  }

  .sidebarMenu ul li a {
    padding: 12px 5px 10px;
    font-size: 13px;
  }

  .sidebarMenu ul li a svg {
    width: auto !important;
    height: 20px !important;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr .memberBox .innermbrbx {
    padding-right: 0px;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr .autoLogin {
    margin: 40px 0px 0px;
    position: relative;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr .addressCopyBtn,
  .addressCopyBtn {
    display: block;
    width: 100%;
    word-wrap: break-word;
    position: relative;
    padding-right: 40px;
    font-size: 14px;
    line-height: 1.1;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr .addressCopyBtn img,
  .addressCopyBtn img {
    position: absolute;
    top: 50%;
    right: 0px;
    width: 35px;
    padding: 6px;
    height: auto;
    transform: translateY(-50%);
  }

  .progileDashBx {
    margin-bottom: 40px;
  }

  .statsPreviewMain .tablemainnet .tableInr table tr td,
  .statsPreviewMain .tablemainnet .tableInr table tr th,
  .partnersPreviewMain .tablemainnet .tableInr table tr td,
  .partnersPreviewMain .tablemainnet .tableInr table tr th {
    white-space: nowrap;
  }

  .levelDetailsTable table tr th {
    &:nth-child(1) {
      flex: 0 0 50%;
      max-width: 50%;
      padding-bottom: 0px;
    }

    &:nth-child(2) {
      flex: 0 0 50%;
      max-width: 50%;
      text-align: right;
      padding-bottom: 0px;
    }

    &:nth-child(3) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding-bottom: 0px;
    }

    &:nth-child(4) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding-bottom: 0px;
    }

    &:nth-child(5) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding-bottom: 0px;
    }
  }

  #transactionUserLevels table tr th {
    &:nth-child(4) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding-bottom: 0px;
      text-align: right;
    }
  }

  #transactionUserLevels table tr td:nth-child(4) {
    flex: 0 0 50%;
    max-width: 50%;
    padding-bottom: 0px;
    justify-content: flex-end;
  }

  .levelDetailsTable table tr td {
    &:nth-child(1) {
      flex: 0 0 50%;
      max-width: 50%;
      padding-bottom: 0px;
    }

    &:nth-child(2) {
      flex: 0 0 50%;
      max-width: 50%;
      text-align: right;
      padding-bottom: 0px;
    }

    &:nth-child(3) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding-bottom: 0px;
    }

    &:nth-child(4) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding-bottom: 0px;
    }

    &:nth-child(5) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding-bottom: 0px;
    }
  }

  .levelDetailsBox .inrBox svg,
  .levelDetailsBox .inrBox img,
  .dashboardMian .inrBox svg,
  .dashboardMian .inrBox img {
    width: 40px !important;
    height: 40px !important;
  }

  .dashboardPreviewMain.dashUserTbl .tableInr .table-responsive table tr th {
    padding: 5px;

    // &:nth-child(1) {
    //   flex: 0 0 0px;
    //   max-width: 0px;
    //   display: none;
    // }

    // &:nth-child(2) {
    //   flex: 0 0 calc(100% - 85px);
    //   max-width: calc(100% - 85px);
    // }

    // &:nth-child(3) {
    //   flex: 0 0 85px;
    //   max-width: 85px;
    //   text-align: right;

    //   a {
    //     width: 100%;
    //   }
    // }

    // &:nth-child(4) {
    //   flex: 0 0 33.33%;
    //   max-width: 33.33%;
    // }

    // &:nth-child(5) {
    //   flex: 0 0 33.33%;
    //   max-width: 33.33%;
    //   text-align: right;

    //   &.linkshr {
    //     display: flex;
    //     align-items: center;
    //   }
    // }

    // &:nth-child(6) {
    //   flex: 0 0 33.33%;
    //   max-width: 33.33%;
    //   padding-right: 0px !important;
    // }
  }

  .dashboardPreviewMain.dashUserTbl .tableInr .table-responsive table tr td {
    font-size: 14px;

    // &:nth-child(1) {
    //   flex: 0 0 40px;
    //   max-width: 40px;
    // }

    // &:nth-child(2) {
    //   flex: 0 0 calc(100% - 125px);
    //   max-width: calc(100% - 125px);
    // }

    // &:nth-child(3) {
    //   flex: 0 0 85px;
    //   max-width: 85px;
    //   text-align: right;

    //   a {
    //     width: 100%;
    //   }
    // }

    // &:nth-child(4) {
    //   flex: 0 0 33.33%;
    //   max-width: 33.33%;
    // }

    // &:nth-child(5) {
    //   flex: 0 0 33.33%;
    //   max-width: 33.33%;
    //   text-align: right;

    //   &.linkshr {
    //     display: flex;
    //     align-items: center;
    //     justify-content: flex-end;
    //   }
    // }

    // &:nth-child(6) {
    //   flex: 0 0 33.33%;
    //   max-width: 33.33%;
    // }
  }

  footer .socialIcons ul {
    margin: 20px 0px 0px;
    justify-content: center;
  }

  #root {
    padding-bottom: 65px;
  }
}

@media only screen and (max-width: 575px) {
  .connectWalletToggle {
    .modal-dialog {
      margin: 0px 20px;
    }

    .popupbtngrp {
      .btngrp {
        flex-basis: calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin: 0px 5px 10px;
      }

      a {
        font-size: 14px;
        padding: 20px 8px;

        img {
          margin: 0 auto 10px;
          height: 35px;
        }
      }
    }
  }

  .registerMian .inrBox {
    padding: 20px 8px;

    h3 {
      font-size: 16px;
    }
  }

  .tblMainnetMain .tablemainnet .mainnetInr {
    padding: 20px;
  }

  .tblMainnetMain .tablemainnet .tableInr {
    padding: 20px 0px 20px 20px;
  }

  .tblMainnetMain .tablemainnet .col-md-6 {
    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }

  .tblMainnetMain .tablemainnet .mainnetInr h3 {
    font-size: 24px;
  }

  .registerMian .uplineBox {
    margin: 0px auto 30px;
  }

  .titleBox {
    margin-bottom: 15px;
  }

  header::before {
    height: 70px;
  }

  .ox_banner .slider-container::before {
    height: 40px;
  }

  .accountPreviewMain .tablemainnet .mainnetInr {
    padding: 20px 15px;
  }

  .loginMian .inrBox,
  .registerMian .inrBox {
    padding: 20px 10px;
  }

  .homePageMain .tableInr table tr {
    flex-basis: 100%;
    max-width: 100%;
    margin: 0px 0px 12px;
  }

  .homePageMain .counterBox .btnGrpBox .am_btn {
    margin: 0px 7.5px 15px;
    min-width: 125px;
    font-size: 14px;
  }

  .dashboardMian .inrBox h3 {
    font-size: 24px;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr.profileBox {
    padding: 20px 20px;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr.profileBox h3 {
    flex-basis: 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .inputBox h2 {
    flex-basis: calc(100% - 95px);
    max-width: calc(100% - 95px);
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr .inputBox .am_btn {
    flex: 0 0 75px;
    max-width: 75px;
    padding: 15px 5px;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr,
  .royaltyPreviewMain .tablemainnet .mainnetInr {
    padding: 20px;
  }

  .dashboardMian .inrBox {
    padding: 20px 10px;
  }

  .dashboardPreviewMain .tablemainnet .tableInr {
    padding: 20px 0px 20px 20px;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr .linkBox strong,
  .accountPreviewMain .tablemainnet .mainnetInr .linkBox strong {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 400;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm p {
    font-size: 14px;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr .linkBoxBtm span {
    font-size: 16px;
    margin-left: 10px;
  }

  .homePageMain .tableInr {
    padding: 20px;
  }

  .informationPreviewMain .tablemainnet .mainnetInr {
    padding: 20px;
  }

  .dashboardPreviewMain .tablemainnet .tableInr .table-responsive,
  .accountPreviewMain .tablemainnet .tableInr .table-responsive,
  .loaderData {
    max-height: 410px;
  }

  .royaltyTableMain .tableInr table tr th,
  .royaltyTableMain .tableInr table tr td {
    font-size: 14px;
    width: auto;
  }

  footer nav a {
    margin-right: 2px;
    margin-left: 2px;
    padding: 10px 2px;
    font-size: 12px;
  }

  footer nav a .material-icons svg.MuiSvgIcon-root {
    max-height: 25px;
    max-width: 25px;
    min-height: 25px;
  }

  footer nav {
    height: 65px;
  }



  .treeBoxSingleView .sidepnl {
    flex: 0 0 100%;
    max-width: 100%;
    min-height: 40px;
  }

  .treeBoxSingleView .treeBoxSingleInr {
    flex-basis: calc(100% - 0px);
    max-width: calc(100% - 0px);
    padding: 0px;
  }

  .treeBoxSingleView .treebox .itemsmain span {
    width: 60px;
    height: 60px;
    margin-bottom: 5px;
  }

  .treeBoxSingleView .treebox .itemsmain .col6 .col6 span {
    width: 45px;
    height: 45px;
    font-size: 18px;
  }

  .treeBoxSingleView .treebox .itemsmain .col6 .col6 .col6 span {
    width: 25px;
    height: 25px;
  }

  .treeAeromatix .treebox {
    padding: 10px;
  }

  .treeBoxSingleView .treebox .itemsmain .col6 .col6 .col6 .col6 span {
    width: 18px;
    height: 18px;
  }

  .treeBoxSingleView .treeboxDetail .itemsmain span {
    width: 60px;
    height: 60px;
    font-size: 26px;
  }

  .treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 span {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }

  .treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 .col6 span {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }

  .treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 .col6 .col6 span {
    width: 18px;
    height: 18px;
    font-size: 14px;
  }

  .am_btn {
    padding: 12px 12px;
    min-width: auto;
  }

  .accountPreviewMain .tablemainnet .mainnetInr .inputBox input {
    font-size: 16px;
  }

  .accountPreviewMain .tablemainnet .col-md-6:nth-last-child(1) {
    margin-bottom: 0px;
  }

  .loginMian .inrBox h3 {
    font-size: 28px;
    margin-top: 10px;
  }

  .accountPreviewMain .tablemainnet .tableInr table tr th {
    width: auto;
  }

  .accountPreviewMain .tablemainnet .mainnetInr .linkBoxBtm {
    margin: 5px 0px 0px;
  }

  .accountPreviewMain .tablemainnet .mainnetInr .linkBoxBtm span {
    font-size: 18px;
  }

  .connectWalletToggle .popupbtngrp button,
  .connectWalletToggle .popupbtngrp a {
    font-size: 14px;
    padding: 15px 6px;
  }

  .dashboardPreviewMain .tablemainnet .tableInr table tr th {
    width: auto;
  }

  .dashboardPreviewMain .tablemainnet .mainnetInr h3,
  .royaltyPreviewMain .tablemainnet .mainnetInr h3 {
    font-size: 20px;
  }

  .connectWalletToggle .popupbtngrp .btngrp {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin: 0px 5px 10px;
  }

  .dateFormSection .dateFormInr {
    .col-sm-3 {
      margin-bottom: 10px;
    }
  }

  .am_btn.cnctwlthedBtn {
    font-size: 14px;
  }

  .loginMian .inrBox p {
    margin: 0px 0px 10px;
    line-height: 1.2;
    font-size: 14px;
  }

  .loginMian {
    padding-top: 30px;
    padding-bottom: 10px;
  }

  .accountPreviewMain .tablemainnet .tableInr table {
    min-width: auto;
  }

  .accountPreviewMain .tablemainnet .tableInr table tr {
    flex-wrap: wrap;
  }

  .accountPreviewMain .tablemainnet .tableInr table tr td:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;
  }

  .accountPreviewMain .tablemainnet .tableInr table tr td:nth-child(2),
  .accountPreviewMain .tablemainnet .tableInr table tr td:nth-child(3) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .accountPreviewMain .tablemainnet .tableInr table tr td.text-end a {
    padding: 0px;
  }

  .accountPreviewMain .tablemainnet .tableInr table tr td.txtinf>span:nth-child(1) {
    margin-left: 0px;
    margin-right: 5px;
  }

  .accountPreviewMain .tablemainnet .mainnetInr .autoLogin .am_btn {
    display: block;
    width: 100%;
    margin: 0px 0px 10px !important;
    text-decoration: none !important;
  }

  .accountPreviewMain .tablemainnet .mainnetInr .autoLogin.loginwithv1v2 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .accountPreviewMain .tablemainnet .mainnetInr .autoLogin.loginwithv1v2>a {
    width: calc(50% - 10px);
    text-decoration: none;
    display: inline-block;
    margin: 0 5px;
  }

  .sidebarMenu ul li a {
    font-size: 8px;
  }

  .sidebarMenu ul li a span {
    transform: scale(1.3);
  }

  .mainBoxWrap .rightBoxMain {
    padding-bottom: 50px;
  }

  .treeLevels .accordion-body .inrBoxList>.col-12 {
    margin-bottom: 7px;
  }

  .treeLevels .accordion-body .inrBoxList .row {
    border-radius: 3px;
  }

  .treeLevels .accordion-body {
    padding: 20px 15px 10px;
  }

  .levelDetailsTable table tr td a.idbox {
    font-size: 14px;
  }

  .levelDetailsTable table tr th,
  .levelDetailsTable table tr td {
    font-size: 14px;
  }

  header .rightboxmenu .profLogo {
    max-width: 35px;
    margin-left: 8px;
  }

  header .rdn-control {
    padding: 8px;
    font-size: 12px;
    padding-right: 20px;
  }

  header .rdn-control-arrow-icon {
    top: 13px;
    right: 5px;
  }

  .ox_banner .slider-container .buyogaBtn {
    position: relative;
    bottom: 0px;
    right: 0px;
    padding: 15px 10px;
    text-align: center;
    margin: 20px auto 0px;
    max-width: 200px;
    display: block;
  }
}

@media only screen and (max-width: 390px) {
  .am_btn.cnctwlthedBtn {
    font-size: 14px;
    padding: 10px 8px;

    svg {
      width: 14px !important;
      margin-right: 4px;
    }
  }

  .sidebarMenu ul {
    overflow: auto;
  }

  .sidebarMenu ul li a span {
    white-space: nowrap;
  }

  // .dashboardPreviewMain.dashUserTbl .tableInr .table-responsive table tr td:nth-child(4) {
  //   flex: 0 0 40%;
  //   max-width: 40%;
  //   padding-bottom: 4px;
  // }

  // .dashboardPreviewMain.dashUserTbl .tableInr .table-responsive table tr td:nth-child(5) {
  //   flex: 0 0 60%;
  //   max-width: 60%;
  //   text-align: right;
  //   padding-bottom: 4px;
  // }

  // .dashboardPreviewMain.dashUserTbl .tableInr .table-responsive table tr td:nth-child(6) {
  //   flex: 0 0 100%;
  //   max-width: 100%;
  //   text-align: right !important;
  //   padding: 0px;
  // }

  .accountPreviewMain .tablemainnet .mainnetInr.loginwithv1v2Main {
    padding: 15px !important;
  }

  .accountPreviewMain .tablemainnet .mainnetInr .autoLogin.loginwithv1v2>a button {
    padding: 15px 2px;
  }
}

@media only screen and (max-width: 360px) {
  .connectWalletToggle {
    .modal-dialog {
      margin: 15px;
    }

    .modal-content {
      .modal-header {
        padding: 15px 15px 10px;
      }
    }

    .popupbtngrp {
      a {
        padding: 20px 8px;
      }
    }
  }

  footer nav a {
    font-size: 10px;
  }

  .treeBoxSingleView .treeboxDetail .itemsmain span img {
    padding: 10px;
  }

  .treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 .col6 span {
    width: 30px;
    height: 30px;
    font-size: 16px;

    img {
      padding: 4px;
    }
  }

  .treeBoxSingleView .treeboxDetail .itemsmain .col6 .col6 .col6 .col6 span {
    width: 15px;
    height: 15px;
    font-size: 12px;
  }

  .am_btn.cnctwlthedBtn {
    font-size: 14px;
    padding: 10px 8px;

    svg {
      display: none;
    }
  }
}