@use '../../Assets/css/var';

*,
*::before,
*::after {
    box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}


html {
    scroll-behavior: smooth;
}

body {
    background-color: #0f0d1e;
    color: #ffffff;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    background-image: url(../images/bodybg.gif);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    position: relative;
    font-weight: 300;
    line-height: 1.4;
    min-height: 100vh;
    // background-blend-mode: color;

    &::before {
        content: "";
        position: fixed;
        background-color: #000000;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: var.$colour-theme-dark;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: var.$colour-theme;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background-color: var.$colour-theme-dark;
} */
img {
    max-width: 100%;
    height: auto;
}

.container,
.slider-container {
    max-width: 1320px;
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
}

.loaderData {
    position: relative;
    min-height: 375px;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url('../images/loading.gif');
        width: 50px;
        height: 50px;
        z-index: 9;
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: contain;
    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: 8;
        background-repeat: no-repeat;
        background-color: transparent;
        box-shadow: inset 0px 0px 35px 3px rgba(229, 149, 255, 0.36);
    }

    &#NFTSLevelDetails {
        margin: 0px;
    }

    &#treeViewMain {
        min-height: 330px;
        overflow: visible;

        .col6 {
            opacity: 0;
        }

        &::after {
            display: none;
        }
    }
}

#root {
    min-height: 100vh;
    padding-bottom: 0px;
}

.am_btn {
    background-color: #bf93ff;
    border: none;
    color: #000000;
    font-size: 20px;
    padding: 12px 10px;
    border-radius: 5px;
    line-height: 1;
    display: inline-block;
    text-decoration: none;
    min-width: auto;
    font-weight: 700;
    transition: all 0.3s ease-in-out;

    &:hover {
        opacity: 0.8;
    }

    svg {
        width: 22px !important;
        height: auto !important;
        margin-right: 7px;
        position: relative;
        top: -1px;
    }

    .loader {
        display: block;
        height: 20px;
        width: 20px;
        margin: 0 auto;
        text-align: center;

        img {
            display: block;
            transform: scale(1.4);
            margin: 0 auto;
        }
    }

    &.cnctwlthedBtn {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        align-items: center;
        background-color: #daa629;
    }
}

.titleBox {
    text-align: left;
    margin-bottom: 25px;

    h2 {
        font-size: 18px;
        margin: 0 0 15px;
        display: flex;
        justify-content: space-between;

        .backIcon {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-decoration: none;
            transform-origin: left;
            width: 20px;
            height: 20px;
            color: #ffffff;
            font-size: 16px;

            span {
                margin-right: 5px;
                display: inline-block;
            }
        }
    }

    p {
        font-size: 14px;
        font-weight: 300;
        opacity: 0.5;
        margin: 0px;
    }
}

input,
select {
    outline: none;
    box-shadow: none
}

// Loading
body {
    &.loadingpage {
        #root {
            filter: grayscale(1);
            pointer-events: none;
        }


        &::after {
            content: "Loading...";
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #ffffff;
            z-index: 999999;
            display: block;
            opacity: 1;
            mix-blend-mode: unset;
            filter: unset;
            width: 100%;
            text-align: center;
            padding: 15px;
            font-weight: 400;
            font-size: 18px;
            letter-spacing: 1.5px;
        }

        &::before {
            opacity: 0;
        }

        .logo,
        .am_btn,
        .ox_banner .slider-frame,
        .titleBox h2,
        .titleBox p,
        h3,
        input,
        h1,
        p,
        strong,
        span,
        table tr,
        footer nav,
        label,
        .rdn-control,
        .material-icons,
        .treeAeromatix .treebox .cartBox button,
        .dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .imgBox {
            background-color: #2a2a2a;
            background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(90, 90, 90, 0.5) 50%, rgba(255, 255, 255, 0) 60%) #2a2a2a;
            background-size: 200% 100%;
            background-position-x: 180%;
            animation: 1s loading ease-in-out infinite;
            color: transparent !important;
            position: relative;
            box-shadow: none;
            display: inline-block;
            filter: opacity(0.3);
            border: none;

            img {
                opacity: 0;
            }

            &::placeholder {
                color: transparent;
            }
        }

        .main .dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .inputBox h2 {
            background-color: #2a2a2a;
            background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(90, 90, 90, 0.5) 50%, rgba(255, 255, 255, 0) 60%) #2a2a2a;
            background-size: 200% 100%;
            background-position-x: 180%;
            animation: 1s loading ease-in-out infinite;
            color: transparent !important;
            position: relative;
            box-shadow: none;
            display: inline-block;
            filter: opacity(0.3);
            border: none;
            opacity: 1;
            visibility: visible;
        }

        .logo {
            transform: scaleY(0.8);
        }

        .titleBox h2,
        h3 {
            display: inline-block;
        }

        .titleBox p,
        p {
            display: block;
        }

        .slider-control-bottomcenter,
        table tr td,
        .accountPreviewMain .tablemainnet .mainnetInr .linkBox span svg,
        footer nav li,
        .rdn-drop,
        .dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .prfllgo,
        .dashboardPreviewMain .tablemainnet .mainnetInr.profileBox .inputBox h2,
        .treeAeromatix .treebox .row,
        .treeAeromatix .treebox .itemsmain,
        .dashboardPreviewMain .tablemainnet .mainnetInr .linkBox span svg,
        .dashboardPreviewMain .tablemainnet .mainnetInr a,
        .statsPreviewMain .tablemainnet .tableInr table tr th,
        .partnersPreviewMain .tablemainnet .tableInr table tr th,
        footer {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }

        @keyframes loading {
            100% {
                background-position-x: -20%;
            }
        }

    }
}

.referralBtn {
    display: block;
    background-color: transparent;
    border: 1px solid #ffffff;
    margin-top: 8px;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 12px;
}

.autoLoginGrp {
    button {
        &:nth-child(1) {
            margin-right: 15px;
        }
    }
}

.addressCopyBtn {
    display: flex;
    align-items: center;
    border: 1px solid #747474;
    background-color: transparent;
    color: #ffffff;
    margin-top: 5px;
    padding: 8px 10px;
    border-radius: 3px;
    font-size: 16px;
    text-align: left;

    img {
        filter: invert(1);
        width: 25px;
        height: auto;
        margin-left: 10px;
        border-left: 1px solid rgba(0, 0, 0, 0.361);
        padding-left: 6px;
        cursor: pointer;
    }
}

.mainBoxWrap {
    .rightBoxMain {
        flex: 0 0 calc(100% - 230px);
        max-width: calc(100% - 230px)
    }
}

#root .imgBxnft {
    position: relative;

    .cartBox {
        margin: 0px auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 9;

        a {
            width: 100%
        }
    }
}
.mainPopupHome{
    .modal-body{
        padding: 0px !important;
        img{
            border-radius: 10px;
        }
    }
}
// .cartBox+.imgaeboxNft {
//     position: relative;
//     cursor: default;

//     .upgradeView {
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         width: 100%;
//         text-align: center;
//         transform: translateX(-50%);
//         font-size: 16px;
//         z-index: 99;
//     }

//     .imgBxnft {
//         outline: 1px solid #ffffff;
//         border-radius: 3px;


//         img {
//             opacity: 0.2;
//             border-radius: 3px;
//         }
//     }
// }

// Media Query Css

@import 'media';