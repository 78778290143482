@use '../../Assets/css/var';

.sidebarMenu {
    flex: 0 0 220px;
    max-width: 220px !important;
    margin-right: 10px;
    padding-top: 50px;
    position: sticky;
    top: 0px;
    align-self: flex-start;

    ul {
        padding: 0px;
        margin: 0px;
        list-style: none;

        li {
            margin-bottom: 15px;

            a {
                color: #ffffff;
                text-decoration: none;
                padding: 10px 0px;
                display: flex;
                align-items: center;

                span {
                    position: relative;
                    top: 2px;
                }

                svg {
                    margin-right: 20px;
                }

                &.active {
                    color: #bf93ff;
                    font-weight: bold;

                    &+a {
                        color: #bf93ff;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}