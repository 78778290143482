@use '../../Assets/css/var';

.ox_banner {
    .slide {
        img {
            height: 50vw;
            width: 100%;
            object-fit: cover;
            max-height: 350px;
            background-color: rgba(104, 86, 133, 0.17);
        }
    }

    .paging-item {
        button {
            padding: 6px;
            opacity: 1 !important;

            svg {
                width: 10px;
                height: 10px;
                fill: #ffffff !important;
            }
        }

        &.active {
            button {
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    .slider-container {
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        .buyogaBtn {
            position: absolute;
            bottom: 15px;
            right: 25px;
            z-index: 3;
            font-size: 14px;
            opacity: 1 !important;
            background-color: #040300;
            color: #ffffff;
            border: 1px solid #daa629 !important;
            padding: 12px 10px;
            border-radius: 5px;
            line-height: 1;
            display: inline-block;
            font-weight: 400;
            transition: all 0.3s ease-in-out;
            text-decoration: none;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background-image: linear-gradient(transparent, #000000);
            z-index: 1;
            pointer-events: none;
        }

        .paging-dot {
            fill: #d4b6ff;
        }

        .slider-control-centerleft {
            display: none;
        }

        .slider-control-centerright {
            display: none;
        }
    }
}