@use '../../Assets/css/var';

.registerMian {
    padding-top: 50px;
    padding-bottom: 30px;

    .uplineBox {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 400px;
        margin: 0 auto 25px;

        label {
            display: block;
            flex: 0 0 120px;
            max-width: 120px;
            font-size: 18px;
            margin-right: 10px;
        }

        input {
            display: block;
            width: 100%;
            flex: 0 0 calc(100% - 130px);
            max-width: calc(100% - 130px);
            font-size: 18px;
            line-height: 1;
            border: none;
            background-color: #ffffff;
            border-radius: 5px;
            padding: 10px 15px;
            color: #000000;
        }
    }

    .col-6 {
        margin-bottom: 25px;
    }

    .inrBox {
        padding: 20px 20px 15px;
        text-align: left;
        border-radius: 10px;
        height: 100%;
        position: relative;
        background: radial-gradient(circle, rgba(0, 0, 0, 0), rgb(55, 32, 88));
        border-top: 2px ridge #ffc73f;
        border-left: 2px groove #ffc73f;
        border-right: 2px ridge #ffc73f;
        border-bottom: 2px groove #ffc73f;
        box-shadow: inset 0px 0px 5px 3px rgba(1, 1, 1, 0.3);

        h3 {
            font-weight: 400;
            font-size: 20px;
            margin-bottom: 0px;
            display: block;
        }

        i {
            font-size: 30px;
            display: inline-block;
            margin-bottom: 15px;
            word-wrap: break-word;
            word-break: break-all;
            line-height: 1;
        }

        &.done {
            i {
                color: #aeffae;
            }

            h3 {
                color: #aeffae;
            }
        }

        &.error {
            i {
                color: #ffbdb1;
            }

            h3 {
                color: #ffbdb1;
            }
        }
    }

    .brn-grp {
        margin-top: 10px;

        // .am_btn {
        //     padding: 15px 30px;
        // }
    }

    .tablemainnet {

        .col-md-6 {
            margin-bottom: 30px;
        }

        .mainnetInr {
            padding: 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 40px rgb(92 92 92 / 25%);
            height: 100%;

            h3 {
                font-weight: 400;
                font-size: 28px;
                margin-bottom: 15px;

                span {
                    color: var.$colour-theme;
                }
            }

            .am_btn {
                margin-top: 20px;
            }
        }

        .tableInr {
            padding: 30px 0px 30px 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 40px rgba(92, 92, 92, 0.25);
            height: 100%;

            h3 {
                font-weight: 400;
                font-size: 28px;
                margin-bottom: 15px;

                span {
                    color: var.$colour-theme;
                }
            }

            .table-responsive {
                overflow-x: auto;
                max-height: 278px;
            }

            table {
                margin-bottom: 0px;

                tbody>tr:nth-of-type(odd)>* {
                    background-color: #0c0c0c;
                }

                tr {

                    th {
                        border-color: #0b0b0c;
                        color: var.$colour-theme;
                        font-weight: 400;
                        vertical-align: middle;
                    }

                    td {
                        border-color: #0b0b0c;
                        color: #ffffff;
                        font-weight: 400;
                        vertical-align: middle;

                        img {
                            height: 20px;
                            width: auto;
                            display: inline-block;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    .rdn {
        max-width: 410px;
        margin: 0 auto 50px;
    }

    .rdn-control-arrow-icon {
        border-color: var.$colour-theme transparent transparent;
        top: 21px;
    }

    .rdn-control-arrow-icon.is-open {
        border-color: transparent transparent var.$colour-theme;
    }

    .rdn-control {
        background-color: transparent;
        border: 1px solid var.$colour-theme;
        color: var.$colour-white;
        border-radius: 5px;
        padding: 15px;
        text-align: left;
        line-height: 1;
        font-size: 18px;
        margin: 0 auto;
        text-transform: capitalize;
        cursor: pointer;
        width: 100%;

        &.is-open {
            border-radius: 5px 5px 0px 0px;

            &:hover {
                box-shadow: 0px 0px 0px 0px var.$colour-theme;
            }
        }

        &:hover {
            box-shadow: 0px 0px 20px -6px var.$colour-theme;
        }
    }

    .rdn-drop,
    .rdn-selection {
        background-color: #000000;
        border: 1px solid var.$colour-theme;
        box-shadow: 0px 10px 15px -15px var.$colour-theme;
        border-radius: 0px 0px 5px 5px;

        .rdn-drop-menu-option,
        .rdn-drop-menu-group-option,
        .rdn-selection-menu-option,
        .rdn-selection-menu-group-option {
            box-sizing: border-box;
            color: var.$colour-white;
            ;
            cursor: pointer;
            display: block;
            padding: 8px 10px;
            background-color: #181818;
            text-align: left;
            margin-bottom: 1px;
            padding: 10px 15px;
            font-size: 16px;
            text-transform: capitalize;

            &:nth-last-child(1) {
                margin-bottom: 0px;
            }

            &:hover {
                background-color: var.$colour-theme-dark;
            }
        }
    }
}

.tblMainnetMain {
    padding-top: 30px;
    padding-bottom: 30px;

    .tablemainnet {

        .col-md-6 {
            margin-bottom: 30px;
        }

        .mainnetInr {
            padding: 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 40px rgb(92 92 92 / 25%);
            height: 100%;
            text-align: center;
            border: 1px solid #1c1c1c;

            h3 {
                font-weight: 400;
                font-size: 28px;
                margin-bottom: 15px;

                span {
                    color: var.$colour-theme;
                }
            }

            .am_btn {
                margin-top: 20px;
            }
        }

        .tableInr {
            padding: 30px 0px 30px 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 35px 3px rgba(229, 149, 255, 0.36);
            height: 100%;

            h3 {
                font-weight: 400;
                font-size: 28px;
                margin-bottom: 15px;

                span {
                    color: var.$colour-theme;
                }
            }

            .table-responsive {
                overflow-x: auto;
                max-height: 274px;
            }

            table {
                margin-bottom: 0px;

                tbody>tr:nth-of-type(odd)>* {
                    background-color: #262626;
                }

                tr {

                    th {
                        border-color: #0b0b0c;
                        color: var.$colour-theme;
                        font-weight: 400;
                        vertical-align: middle;
                    }

                    td {
                        border-color: #0b0b0c;
                        color: #ffffff;
                        font-weight: 400;
                        vertical-align: middle;

                        img {
                            height: 20px;
                            width: auto;
                            display: inline-block;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}