@use '../../Assets/css/var';

.homePageMain {
    padding-top: 50px;
    padding-bottom: 30px;

    .counterBox {
        text-align: center;
        margin-bottom: 30px;

        h1 {
            font-size: 54px;
            display: block;
            margin: 0px 0px 10px;
            font-size: 400;
        }

        p {
            font-size: 20px;
            margin: 0px;
            color: #00ff00;
        }

        .btnGrpBox {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            .am_btn {
                margin: 0px 10px 20px;
                min-width: 135px;
            }
        }
    }

    .tableInr {
        padding: 30px 30px 30px 30px;
        border-radius: 5px;
        box-shadow: inset 0px 0px 35px 3px rgba(229, 149, 255, 0.36);
        height: 100%;
        max-width: 700px;
        margin: 0 auto;

        h3 {
            font-weight: 400;
            font-size: 24px;
            margin-bottom: 25px;

            span {
                color: var.$colour-theme;
            }
        }

        .table-responsive {
            overflow-x: auto;
            // max-height: 295px;
        }

        table {
            margin-bottom: 0px;
            display: block;

            tbody {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            tr {
                flex: 0 0 calc(50% - 12px);
                max-width: calc(50% - 12px);
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #262626;
                margin: 0px 6px 12px;
                border-radius: 7px;

                th {
                    border-color: #0b0b0c;
                    color: var.$colour-theme;
                    font-weight: 400;
                    vertical-align: middle;
                    padding: 0px;
                }

                td {
                    border-color: #0b0b0c;
                    color: #ffffff;
                    font-weight: 400;
                    vertical-align: middle;
                    border: none;

                    img {
                        height: 30px;
                        width: auto;
                        display: inline-block;
                        margin-right: 20px;
                        padding: 6px;
                        background-color: #dddddd;
                        border-radius: 50px;
                    }

                    svg {
                        height: 24px;
                        width: auto;
                        margin-right: 10px;
                    }

                    a {
                        background-color: #dddddd;
                        display: inline-block;
                        text-align: center;
                        font-size: 18px;
                        text-decoration: none;
                        font-weight: 500;
                        color: #000000;
                        border-radius: 2px;
                        padding: 3px 8px;
                        line-height: 1;
                    }

                    &.linkshr.text-end {
                        padding-right: 20px;
                    }

                    &.txtinf {
                        p {
                            margin: 0px;
                            display: inline-block;
                            color: var.$colour-theme;
                        }

                        span {
                            display: inline-block;
                            margin: 0 10px;
                            color: #9d9d9d;
                        }
                    }

                    &:nth-child(1) {
                        border-radius: 10px 0px 0px 10px;
                    }

                    &:nth-child(2) {
                        border-radius: 0px 10px 10px 0px;
                    }
                }
            }
        }
    }
}