@use '../../Assets/css/var';


.informationPreviewMain {
    padding-top: 30px;
    padding-bottom: 0px;

    .tablemainnet {
        .col-md-6 {
            margin-bottom: 30px;
        }

        .mainnetInr {
            padding: 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 35px 3px rgba(229, 149, 255, 0.36);
            height: 100%;
            border: 1px solid #1c1c1c;

            h3 {
                font-weight: 400;
                font-size: 24px;
                margin-bottom: 15px;
            }

            p {
                display: block;
                color: #b7b7b7;
                margin: 0 0 5px;
            }
        }

    }
}