@use '../../Assets/css/var';

header {
    position: relative;
    top: 0px;
    transition: all 0.3s ease-in-out;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: transparent;
    padding-top: 12px;
    padding-bottom: 12px;

    .rightboxmenu {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .profLogo {
            max-width: 45px;
            height: auto;
            border: 1px solid #daa629;
            display: inline-block;
            margin-left: 15px;
            padding: 2px;
            border-radius: 50px;
        }
    }

    .rdn {
        max-width: 320px;
        margin: 0 0 0 auto;
    }

    .rdn-control-arrow-icon {
        border-color: #daa629 transparent transparent;
        top: 21px;
    }

    .rdn-control-arrow-icon.is-open {
        border-color: transparent transparent #daa629;
    }

    .rdn-control {
        background-color: transparent;
        border: 1px solid #daa629;
        color: #daa629;
        border-radius: 5px;
        padding: 15px;
        text-align: left;
        line-height: 1;
        font-size: 18px;
        margin: 0 auto;
        text-transform: capitalize;
        cursor: pointer;
        padding-right: 30px;

        &.is-open {
            border-radius: 5px 5px 0px 0px;

            &:hover {
                box-shadow: 0px 0px 0px 0px #daa629;
            }
        }

        &:hover {
            box-shadow: 0px 0px 20px -6px #daa629;
        }
    }

    .rdn-drop,
    .rdn-selection {
        background-color: #000000;
        border: 1px solid #daa629;
        box-shadow: 0px 10px 15px -15px #daa629;
        border-radius: 0px 0px 5px 5px;

        .rdn-drop-menu-option,
        .rdn-drop-menu-group-option,
        .rdn-selection-menu-option,
        .rdn-selection-menu-group-option {
            box-sizing: border-box;
            color: var.$colour-white;
            ;
            cursor: pointer;
            display: block;
            padding: 8px 10px;
            background-color: #181818;
            text-align: left;
            margin-bottom: 1px;
            padding: 10px 15px;
            font-size: 16px;
            text-transform: capitalize;

            &:nth-last-child(1) {
                margin-bottom: 0px;
            }

            &:hover {
                background-color: #775300;
            }
        }
    }
}

.logo {
    display: inline-block;
    position: relative;

    img {
        width: 100%;
        max-width: 300px;
        padding: 10px 0px;
    }
}